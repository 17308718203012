body {
  margin: 0;
}

@font-face {
  font-family: "Satoshi";
  src: local("Satoshi"), url(./fonts/Satoshi.ttf) format("truetype");
}

* {
  font-family: "Satoshi", Arial, Helvetica, sans-serif;
}

/* input[type="password"] { */
/* font-family: Verdana; */
/* } */

.content-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 0 24px;
  scroll: none;
}

.animate-appear {
  animation: appear 0.3s ease-in-out;
  opacity: 1;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-loading {
  animation: 1.2s loading ease-in-out infinite;
}

@keyframes loading {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.stripe-form {
  border-radius: 4px;
  padding: 12px;

  margin-bottom: 16px;
  width: 100%;
  box-sizing: border-box;
}

.stripe-form--light {
  border: 1px solid rgba(50, 50, 93, 0.1);
  color: #282828;
}

.stripe-form--light .InputElement {
  color: #282828;
}

.stripe-form--dark {
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff !important;
}

.stripe-form--dark .InputElement {
  color: #fff !important;
}

.stripe-card.StripeElement--focus {
  border: 2px solid #e6007e;
}

.legal-docs {
  padding: 0 24px;
}

.legal-docs p {
  /* Label */
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
}

.legal-docs h2 {
  /* David Smith */

  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  color: #282828;
}

.toast-container {
  top: 0rem !important;
}

.Toastify__toast {
  border-radius: 0;
}

/* INTRO ANIMATION */
.intro__animated-logo-l {
  animation: animatedLogoL 1.5s ease-in-out;
}

.intro__animated-logo-r {
  animation: animatedLogoR 1.5s ease-in-out;
}

@keyframes animatedLogoL {
  0% {
    transform: translateX(-500px) translateY(20vh) scale(1.2) rotate(180deg);
    opacity: 0;
  }

  30% {
    transform: translateX(-20px) translateY(20vh) scale(1.2) rotate(0deg);
  }

  50% {
    opacity: 1;

    transform: translateX(-20px) translateY(20vh) scale(1.2);
  }

  100% {
    transform: translate(0);
  }
}

@keyframes animatedLogoR {
  0% {
    transform: translateX(500px) translateY(20vh) scale(1.2) rotate(-180deg);
    opacity: 0;
  }

  30% {
    transform: translateX(20px) translateY(20vh) scale(1.2) rotate(0deg);
  }

  50% {
    opacity: 1;

    transform: translateX(20px) translateY(20vh) scale(1.2);
  }

  100% {
    transform: translate(0);
  }
}

.intro__animated-x {
}

.intro__animated-body {
  animation: animatedBody 1.5s ease-in-out;
}

@keyframes animatedBody {
  0% {
    opacity: 0;
    transform: translateY(100vh);
  }

  50% {
    opacity: 0;
    transform: translateY(100vh);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hideScrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
